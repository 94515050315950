import { initialsWeekDaysBR } from './WeekDay';

const formatterPercent = (
  value: string | number,
  params?: {
    fractionDigits?: number;
    notation?: Intl.NumberFormatOptions['notation'];
  }
) =>
  new Intl.NumberFormat('pt-br', {
    style: 'percent',
    notation: params?.notation,
    minimumFractionDigits: params?.fractionDigits,
    maximumFractionDigits: params?.fractionDigits
  }).format(Number(value) / 100);

const formatterNumber = (
  value: string | number,
  params?: {
    fractionDigits?: number;
    notation?: Intl.NumberFormatOptions['notation'];
    maxIntDigits?: number;
  }
) =>
  new Intl.NumberFormat('pt-br', {
    notation: params?.notation,
    minimumFractionDigits: params?.fractionDigits,
    maximumFractionDigits: params?.fractionDigits,
    maximumSignificantDigits: params?.maxIntDigits
  }).format(Number(value));

const formatterNumberShort = (
  value: number,
  onlyNumber?: boolean,
  onlyText?: boolean
) => {
  if (value >= 1.0e12) {
    if (onlyNumber) {
      return (value / 1.0e12)?.toFixed(1).toString();
    }
    if (onlyText) {
      return ' Trilhões';
    }
    return `${(value / 1.0e12)?.toFixed(1).replace('.', ',')} Trilhões`;
  }
  if (value >= 1.0e9) {
    if (onlyNumber) {
      return (value / 1.0e9)?.toFixed(1).replace('.', ',').toString();
    }
    if (onlyText) {
      return ' Bilhões';
    }
    return `${(value / 1.0e9)
      ?.toFixed(1)
      .replace('.', ',')
      .toString()} Bilhões`;
  }
  if (value >= 1.0e6) {
    if (onlyNumber) {
      return (value / 1.0e6)?.toFixed(1).replace('.', ',').toString();
    }
    if (onlyText) {
      return ' Milhões';
    }
    return `${(value / 1.0e6)
      ?.toFixed(1)
      .replace('.', ',')
      .toString()} Milhões`;
  }
  if (value >= 1.0e3) {
    if (onlyNumber) {
      return (value / 1.0e3)?.toFixed(1).replace('.', ',').toString();
    }
    if (onlyText) {
      return ' Mil';
    }
    return `${(value / 1.0e3)?.toFixed(1).replace('.', ',').toString()} Mil`;
  }
  // formata para padrão brasileiro
  return value?.toFixed(2).replace('.', ',');
};

const formatterCurrency = (
  value: string | number,
  params?: {
    fractionDigits?: number;
    notation?: Intl.NumberFormatOptions['notation'];
  }
) =>
  new Intl.NumberFormat('pt-br', {
    style: 'currency',
    currency: 'BRL',
    notation: params?.notation,
    minimumFractionDigits: params?.fractionDigits,
    maximumFractionDigits: params?.fractionDigits
  }).format(Number(value));

type ResultType = {
  sequence: string[];
  others: string[];
};

const formatterDaysExhibition = (days: string[]) => {
  if (days.length === 1) return days[0];

  let daysIndex = initialsWeekDaysBR.indexOf(days[0]);
  const result: ResultType = { sequence: [], others: [] };

  for (let index = 0; index < days.length; index += 1) {
    if (initialsWeekDaysBR[daysIndex] === days[index])
      result.sequence.push(days[index]);
    else result.others.push(days[index]);

    daysIndex += 1;
  }

  const { sequence, others } = result;

  if (others.length >= 2) {
    const otherSequence = formatterDaysExhibition(others);
    others.length = 0;
    others.push(otherSequence);
  }

  if (!sequence.length) return '';

  const otherStr = others.length ? ', '.concat(others.join(', ')) : '';
  const sequenceStr =
    sequence.length > 1 ? '-'.concat(sequence[sequence.length - 1]) : '';

  return `${sequence[0]}${sequenceStr}${otherStr}`;
};

export {
  formatterPercent,
  formatterNumber,
  formatterCurrency,
  formatterDaysExhibition,
  formatterNumberShort
};
